export function commons(){
    
    //バーガーメニュー
    $("#HEADER .gnavi").on("click", function () {
        if ($(window).width() < 769) {
            $(this).children("ul").stop().slideToggle(200);
            if ($(this).is('.addClose')) {
                $(this).removeClass('addClose');
            } else {
                $(this).addClass('addClose');
            }
        }
    });
}